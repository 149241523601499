import React from 'react'
import "../Copyrights/Copyrights.css";

export const Copyrights = () => {
  return (
    <div className='copy-container'>
        <p style={{color:'white',fontWeight:'bold',paddingLeft:'30px',margin:'0%'}}>Developed by teckzy</p> 
        <p style={{color:'white',fontWeight:'bold',paddingLeft:'30px',paddingBottom:"10px",margin:'0%'}}>© 2023 Mithraa sports private limited.| All rights reserved.</p>
    </div>
  )
}

export default Copyrights;